<template>
    <div class="login-container">
      <div class="login-box">
        <h1 class="login-title">KIRJAUDU SISÄÄN</h1>
  
        <v-alert
          v-if="errorMessage"
          type="error"
          variant="tonal"
          closable
          class="mb-4"
          @click:close="clearErrorMessage"
        >
          {{ errorMessage }}
        </v-alert>
  
        <div class="login-form">
          <div class="input-wrapper">
            <v-text-field
              v-model="username"
              label="Käyttäjätunnus"
              variant="outlined"
              :disabled="loading"
              @keyup.enter="handleLogin"
              bg-color="grey-darken-3"
              class="input-field"
            ></v-text-field>
          </div>
  
          <div class="input-wrapper">
            <v-text-field
              v-model="password"
              label="Salasana"
              variant="outlined"
              :type="showPassword ? 'text' : 'password'"
              :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append-inner="showPassword = !showPassword"
              :disabled="loading"
              @keyup.enter="handleLogin"
              bg-color="grey-darken-3"
              class="input-field"
            ></v-text-field>
          </div>
  
          <v-checkbox
            v-model="rememberMe"
            label="Muista minut"
            :disabled="loading"
            color="primary"
            class="checkbox-label"
            hide-details
          ></v-checkbox>
  
          <v-btn
            block
            color="primary"
            size="large"
            :loading="loading"
            :disabled="!username || !password"
            @click="handleLogin"
            class="login-btn"
          >
            Kirjaudu sisään
          </v-btn>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoginForm',
    data() {
      return {
        username: '',
        password: '',
        showPassword: false,
        rememberMe: false,
        loading: false,
        errorMessage: '',
        lastLoginAttempt: 0,
      }
    },
    methods: {
      clearErrorMessage() {
        this.errorMessage = '';
        localStorage.removeItem('login_error');
      },
      canAttemptLogin() {
        const now = Date.now();
        if (now - this.lastLoginAttempt < 2000) {
          return false;
        }
        this.lastLoginAttempt = now;
        return true;
      },
      async handleLogin() {
        if (!this.username || !this.password) return;
        if (!this.canAttemptLogin()) return;
        
        this.loading = true;
        this.clearErrorMessage();
        
        try {
          const baseUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
            ? 'http://127.0.0.1:5364'
            : 'https://avai.fi';

          const response = await fetch(`${baseUrl}/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.content,
            },
            credentials: 'include',
            body: new URLSearchParams({
              username: this.username,
              password: this.password
            })
          });
  
          const data = await response.json();
  
          if (!response.ok) {
            if (response.status === 429) {
              this.errorMessage = 'Liian monta kirjautumisyritystä. Yritä myöhemmin uudelleen.';
            } else if (response.status === 401) {
              this.errorMessage = `${data.detail || 'Virheelliset tunnukset'}`;
            } else {
              this.errorMessage = data.detail || 'Kirjautuminen epäonnistui';
            }
            throw new Error(data.detail || 'Login failed');
          }
  
          if (data.access_token) {
            const userData = {
              username: this.username,
              lastLogin: new Date().toISOString(),
              token: data.access_token
            };
  
            if (this.rememberMe) {
              localStorage.setItem('userData', JSON.stringify(userData));
            } else {
              sessionStorage.setItem('userData', JSON.stringify(userData));
            }
  
            this.$emit('login-success', userData);
          }
        } catch (error) {
          if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
            this.errorMessage = 'Palvelimeen ei saatu yhteyttä.';
          } else if (error.name === 'AbortError') {
            this.errorMessage = 'Yhteys aikakatkaistiin. Yritä uudelleen.';
          } else if (!this.errorMessage) {
            this.errorMessage = 'Virhe yhteydessä palvelimeen. Yritä uudelleen.';
          }
          
          localStorage.setItem('login_error', this.errorMessage);
        } finally {
          this.loading = false;
        }
      }
    },
    mounted() {
      const storedError = localStorage.getItem('login_error');
      if (storedError) {
        this.errorMessage = storedError;
      }
  
      window.addEventListener('offline', () => {
        this.errorMessage = 'Ei internet-yhteyttä. Tarkista verkkoyhteys.';
        localStorage.setItem('login_error', this.errorMessage);
      });
  
      if (window.socket) {
        window.socket.on('connect_error', () => {
          this.errorMessage = 'Virhe yhdistettäessä palvelimeen. Yritä uudelleen.';
          localStorage.setItem('login_error', this.errorMessage);
        });
  
        window.socket.on('login_attempt_warning', (data) => {
          this.errorMessage = data.message;
          localStorage.setItem('login_error', this.errorMessage);
        });
      }
    },
    beforeUnmount() {
      window.removeEventListener('offline', this.handleOffline);
  
      if (window.socket) {
        window.socket.off('connect_error');
        window.socket.off('login_attempt_warning');
      }
    }
  }
  </script>

<style scoped>
.login-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #121212 0%, #1e1e1e 100%);
  padding: 1rem;
}

.login-box {
  background: #1e1e1e;
  padding: clamp(1rem, 5vw, 2.5rem);
  border-radius: 12px;
  width: 100%;
  max-width: min(400px, 90vw);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.login-title {
  text-align: center;
  margin-bottom: 2rem;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.input-wrapper {
  width: 100%;
}

.input-field {
  width: 100%;
}

:deep(.v-field__outline) {
  --v-field-border-opacity: 0.2;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

:deep(.v-field__input) {
  color: #ffffff !important;
}

:deep(.v-label) {
  color: rgba(255, 255, 255, 0.7) !important;
}

:deep(.checkbox-label .v-label) {
  color: rgba(255, 255, 255, 0.9) !important;
}

:deep(.v-field--variant-outlined .v-field__outline) {
  opacity: 0.2;
}

:deep(.v-field--variant-outlined:hover .v-field__outline) {
  opacity: 0.3;
}

:deep(.v-field--focused .v-field__outline) {
  opacity: 0.4 !important;
}

:deep(.v-btn) {
  text-transform: none;
  font-weight: 500;
  height: 48px;
}

:deep(.v-field__append-inner) {
  padding-inline-start: 12px;
}

.v-alert {
  margin-bottom: 1.5rem;
}

.error-message {
  color: #ff5252;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.attempts-warning {
  color: #fb8c00;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
</style> 
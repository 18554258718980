export const versionInfo = {
  version: '1.1.5',
  lastUpdate: '12.12.2024',
  versionHistory: [
    {
      version: '1.1.5',
      date: '12.12.2024',
      changes: [
        'Paranneltu taustapäivityksiä.',
      ]
    },
    {
      version: '1.1.4',
      date: '10.12.2024',
      changes: [
        'Kerroinlisäys "+" ja katoaminen "-" -kuvakkeet.',
      ]
    },
    {
      version: '1.1.3',
      date: '05.12.2024',
      changes: [
        'Käyttöliittymän parannuksia.',
      ]
    },
    {
      version: '1.1.2',
      date: '02.12.2024',
      changes: [
        'Optimointia.',
        'Aika viimeisestä valuen muutoksesta.',
      ]
    },
    {
      version: '1.1.1',
      date: '29.11.2024',
      changes: [
        'Tilastoja arvioita klikkaamalla.',
      ]
    },
    {
      version: '1.1.0',
      date: '26.11.2024',
      changes: [
        'Kerroinraja näkyy enää kerrointa klikkaamalla.',
        'Kertoimien pyöristäminen yhtenäinen.',
      ]
    },
    {
      version: '1.0.9',
      date: '22.11.2024',
      changes: [
        'Pieniä muutoksia ja korjauksia.',
      ]
    },
    {
      version: '1.0.8',
      date: '12.11.2024',
      changes: [
        'Ottelutietojen haku.',
        'Nubet toimii uudistuksen jälkeen.',
      ]
    },
    {
      version: '1.0.7',
      date: '07.11.2024',
      changes: [
        'Betfair.',
      ]
    },
    {
        version: '1.0.6',
        date: '06.11.2024',
        changes: [
          'Rajoitettu kahteen samanaikaseen kirjautumiseen.',
        ]
      },
    {
        version: '1.0.5',
        date: '05.11.2024',
        changes: [
          'Mobiiliversiota paranneltu.',
          'Lisää äänimerkkejä.',
        ]
      },
    {
        version: '1.0.4',
        date: '04.11.2024',
        changes: [
          'Kerroinpäivitykset tapahtuvat erissä.',
          'Lisätty omien arvioiden muokkaaminen.',
        ]
      },
    {
      version: '1.0.3',
      date: '03.11.2024',
      changes: [
        'Uusi kirjautumissivu.',
      ]
    },
    {
      version: '1.0.2',
      date: '01.11.2024',
      changes: [
        'Uudistettu omat arviot.'
      ]
    },
    {
      version: '1.0.1',
      date: '29.10.2024',
      changes: [
        'Lisätty kerroinrajasäädöt.'
      ]
    },
    {
      version: '1.0',
      date: '27.10.2024',
      changes: [
      ]
    }
  ]
};

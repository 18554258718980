<template>
    <div class="chart-container">
      <Line v-if="hasEnoughData" :data="chartData" :options="chartOptions" />
      <p v-else>Tarvitaan vähintään 3 kerroinliikettä.</p>
    </div>
  </template>
  
  <script>
  import { Line } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale)
  
  export default {
    name: 'OddsHistoryChart',
    components: { Line },
    props: {
      oddsHistory: {
        type: Array,
        required: true
      }
    },
    computed: {
      hasEnoughData() {
        return this.oddsHistory.length >= 2;
      },
      chartData() {
        const history = [...this.oddsHistory].reverse();
        const oddsData = history.flatMap(item => [item.oldValue, item.newValue]);
        const timestamps = history.flatMap(item => [
          new Date(item.timestamp - 1000),
          new Date(item.timestamp)
        ]);
        
        return {
          labels: timestamps.map(time => time.toLocaleTimeString()),
          datasets: [{
            label: 'Kerroin',
            data: oddsData,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        }
      },
      chartOptions() {
        const values = this.chartData.datasets[0].data;
        const minValue = Math.min(...values);
        const maxValue = Math.max(...values);
        const range = maxValue - minValue;
        const padding = range * 0.1;

        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: false,
              suggestedMin: Math.max(0, minValue - padding),
              suggestedMax: maxValue + padding,
              ticks: {
                stepSize: Math.max(0.01, range / 10)
              }
            },
            x: {
              reverse: false
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  const dataIndex = context.dataIndex;
                  const dataset = context.dataset;
                  const value = dataset.data[dataIndex];
                  const change = dataIndex > 0 
                    ? value - dataset.data[dataIndex - 1] 
                    : 0;
                  const changeStr = change !== 0 ? ` (${change > 0 ? '+' : ''}${change.toFixed(3)})` : '';
                  return `Kerroin: ${value}${changeStr}`;
                }
              }
            }
          }
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .chart-container {
    width: 100%;
    height: 400px;
  }
  
  </style>
import pako from 'pako'

export function calculateStorageSize() {
    const jsonString = JSON.stringify(this.oddsHistory);
    const bytes = new TextEncoder().encode(jsonString).length;
    const kilobytes = bytes / 1024;
    return kilobytes;
  }
  
export function isStorageNearlyFull() {
    const currentSize = this.calculateStorageSize();
    const maxSize = 4 * 1024;
    return currentSize > maxSize * 0.9;
  }
  
export function performCleanup() {
    const now = Date.now();
    const isStorageNearlyFull = this.isStorageNearlyFull();
    const timeThreshold = isStorageNearlyFull ? now - 30 * 60 * 1000 : now - 60 * 60 * 1000;
    
    const keysToDelete = [];
    const keysToUpdate = {};
    
    for (const key in this.oddsHistory) {
        const history = decompressOddsHistory(this.oddsHistory[key]);
        const filteredHistory = history.filter(entry => entry.timestamp >= timeThreshold);
        
        if (filteredHistory.length === 0) {
            keysToDelete.push(key);
        } else if (filteredHistory.length !== history.length) {
            keysToUpdate[key] = filteredHistory;
        }
        
        // New code to remove started matches from oddsHistory
        const matchData = this.hiddenMatches[key]; // Assuming key corresponds to matchId
        if (matchData) {
            const startTime = this.parseDateTime(matchData.start_time);
            if (startTime <= now) {
                keysToDelete.push(key); // Mark for deletion
            }
        }
    }
    
    keysToDelete.forEach(key => {
        delete this.oddsHistory[key]; // Remove from oddsHistory
        delete this.hiddenMatches[key]; // Also remove from hiddenMatches
    });
    
    for (const [key, filteredHistory] of Object.entries(keysToUpdate)) {
        try {
            const compressedHistory = pako.deflate(JSON.stringify(filteredHistory));
            this.oddsHistory[key] = compressedHistory;
        } catch (error) {
            console.error(`Error compressing filtered history for key ${key}:`, error);
        }
    }

    // Cleanup hidden matches that have started more than 2 minutes ago
    const twoMinutesInMillis = 2 * 60 * 1000;
    for (const matchId in this.hiddenMatches) {
        const matchData = this.hiddenMatches[matchId];
        const startTime = this.parseDateTime(matchData.start_time);

        if (startTime <= now - twoMinutesInMillis) {
            delete this.hiddenMatches[matchId];
        }
    }

    // Cleanup played matches that have started
    for (const matchId in this.playedMatches) {
        const matchData = this.hiddenMatches[matchId];
        if (matchData) {
            const startTime = this.parseDateTime(matchData.start_time);
            if (startTime <= now - twoMinutesInMillis) {
                delete this.playedMatches[matchId];
            }
        }
    }

    // Update local storage
    localStorage.setItem('hiddenMatches', JSON.stringify(this.hiddenMatches));
    localStorage.setItem('playedMatches', JSON.stringify(this.playedMatches));
}
  
export function encodeOddsHistoryKey(matchId, marketName, outcomeName, bookie, handicap = null) {
    const parts = [matchId, marketName, outcomeName, bookie];
    if (handicap !== null) parts.push(handicap);
    return parts.join('|');
  }
  
export function decodeOddsHistoryKey(key) {
    const parts = key.split('|');
    return {
      matchId: parts[0],
      marketName: parts[1],
      outcomeName: parts[2],
      bookie: parts[3],
      handicap: parts[4] || null
    };
  }

export function decompressOddsHistory(compressedHistory) {
    if (!compressedHistory) return [];
    try {
      const decompressed = pako.inflate(compressedHistory, { to: 'string' });
      return JSON.parse(decompressed);
    } catch (error) {
      console.error('Error decompressing odds history:', error);
      return [];
    }
  }
  
export function trackOddsMovement(matchId, marketName, outcomeName, bookie, oldValue, newValue, handicap = null, timestamp, changeType) {
    if (!this.saveOddsHistory) return;
    const key = encodeOddsHistoryKey(matchId, marketName, outcomeName, bookie, handicap);

    let history = [];
    try {
        history = this.oddsHistory[key] ? decompressOddsHistory(this.oddsHistory[key]) : [];
    } catch (error) {
        console.error('Error decompressing existing history:', error);
    }

    const timestampMs = timestamp * 1000;

    let direction;
    if (changeType === 'added') {
        direction = 'added';
    } else if (changeType === 'removed') {
        direction = 'removed';
    } else {
        const oldValueNum = parseFloat(oldValue);
        const newValueNum = parseFloat(newValue);
        direction = newValueNum > oldValueNum ? 'up' : (newValueNum < oldValueNum ? 'down' : 'unchanged');
    }

    const newEntry = {
        timestamp: timestampMs,
        oldValue: oldValue !== undefined ? parseFloat(oldValue) : null,
        newValue: newValue !== undefined ? parseFloat(newValue) : null,
        direction: direction
    };

    history.push(newEntry);

    if (history.length > 10) {
        history = history.slice(-10);
    }

    try {
        const compressedHistory = pako.deflate(JSON.stringify(history));
        this.oddsHistory[key] = compressedHistory;
    } catch (error) {
        console.error('Error compressing odds history:', error);
    }

    this.lastUpdateTimestamp = timestampMs;

    // Update UI (if needed)
    this.$nextTick(() => {
        const oddsCells = document.querySelectorAll(`.odds-cell[data-match-id="${matchId}"][data-market="${marketName}"][data-outcome="${outcomeName}"][data-bookie="${bookie}"]${handicap ? `[data-handicap="${handicap}"]` : ''}`);
        oddsCells.forEach(cell => {
            cell.classList.add(`odds-${direction}-flash`);
            setTimeout(() => {
                cell.classList.remove(`odds-${direction}-flash`);
            }, 2000);
        });
    });
}
  
export function getOddsMovement(matchId, marketName, outcomeName, bookie, handicap = null, returnType = 'class') {
    if (!this.saveOddsHistory) return '';
    const key = encodeOddsHistoryKey(matchId, marketName, outcomeName, bookie, handicap);
    const compressedHistory = this.oddsHistory[key];
    
    if (!compressedHistory) {
        return returnType === 'class' ? '' : 'none';
    }
    
    try {
        const history = JSON.parse(pako.inflate(compressedHistory, { to: 'string' }));
        
        if (history.length === 0) {
            return returnType === 'class' ? '' : 'none';
        }

        const currentOdds = history[history.length - 1].newValue;
        let comparisonOdds;

        if (history.length >= 5) {
            comparisonOdds = history[history.length - 5].newValue;
        } else if (history.length > 1) {
            comparisonOdds = history[0].newValue;
        } else {
            comparisonOdds = history[0].oldValue;
        }

        let direction;
        if (!comparisonOdds || isNaN(comparisonOdds)) {
            direction = 'added';
        } else if (!currentOdds || isNaN(currentOdds) || currentOdds === 0) {
            direction = 'removed';
        } else if (currentOdds > comparisonOdds) {
            direction = 'up';
        } else if (currentOdds < comparisonOdds) {
            direction = 'down';
        } else {
            direction = 'unchanged';
        }

        return returnType === 'class' ? `odds-${direction}` : direction;
    } catch (error) {
        console.error('Error decompressing odds history:', error);
        return returnType === 'class' ? '' : 'none';
    }
}
  
export function showOddsHistoryModal(matchId, marketName, outcomeName, bookie, handicap = null) {
  if (!this.saveOddsHistory) {
    alert('Kerroinhistoria on pois käytöstä. Ota se käyttöön asetuksista.');
    return;
  }

  const updateHistory = () => {
    const key = encodeOddsHistoryKey(matchId, marketName, outcomeName, bookie, handicap);
    const compressedHistory = this.oddsHistory[key];
    
    if (compressedHistory) {
      try {
        const decompressedHistory = decompressOddsHistory(compressedHistory);
        this.selectedOddsHistory = decompressedHistory.slice().reverse();
      } catch (error) {
        console.error('Error decompressing odds history:', error);
        alert('Virhe kerroinhistorian lataamisessa.');
        this.selectedOddsHistory = [];
      }
    } else {
      this.selectedOddsHistory = [];
    }
  };

  // Initial update
  updateHistory();
  this.showOddsHistory = true;

  // Start interval updates
  this.startOddsHistoryUpdates = () => {
    this.oddsHistoryUpdateInterval = setInterval(updateHistory, 1000);
  };

  // Start updates immediately
  this.startOddsHistoryUpdates();
}

export function batchTrackOddsMovements(movements) {
    if (!this.saveOddsHistory) return;
    
    const batchUpdates = {};
    
    movements.forEach(({ matchId, marketName, outcomeName, bookie, oldValue, newValue, handicap, timestamp }) => {
      const key = encodeOddsHistoryKey(matchId, marketName, outcomeName, bookie, handicap);
      if (!batchUpdates[key]) {
        batchUpdates[key] = [];
      }
      batchUpdates[key].push({ oldValue, newValue, timestamp });
    });
    
    for (const [key, updates] of Object.entries(batchUpdates)) {
      let history = this.oddsHistory[key] ? decompressOddsHistory(this.oddsHistory[key]) : [];
      
      updates.forEach(update => {
        const { oldValue, newValue, timestamp } = update;
        const oldValueNum = parseFloat(oldValue);
        const newValueNum = parseFloat(newValue);
        
        if (!isNaN(oldValueNum) && !isNaN(newValueNum) && oldValueNum !== newValueNum) {
          history.push({
            timestamp: timestamp * 1000,
            oldValue: oldValueNum,
            newValue: newValueNum,
            direction: newValueNum > oldValueNum ? 'up' : 'down'
          });
        }
      });
      
      history = history.slice(-10);
      
      try {
        const compressedHistory = pako.deflate(JSON.stringify(history));
        this.oddsHistory[key] = compressedHistory;
      } catch (error) {
        console.error('Error compressing odds history:', error);
      }
    }
  }

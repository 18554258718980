import beep1 from './sounds/beep1.mp3'
import beep2 from './sounds/beep2.mp3'
import beep3 from './sounds/beep3.mp3'
import beep4 from './sounds/beep4.mp3'
import beep5 from './sounds/beep5.mp3'
import beep6 from './sounds/beep6.mp3'
import sound1 from './sounds/sound1.mp3'
import sound2 from './sounds/sound2.mp3'

export function getOppositeHandicap(handicap) {
    if (handicap === '0') return '0';
    if (handicap.startsWith('-')) {
        return handicap.slice(1);
    } else {
        return '-' + handicap;
    }
}

export function calculateFair3WayOdds(bookmakerOdds, outcomeIndex, originalHomeOdds, originalDrawOdds, originalAwayOdds) {
  if (bookmakerOdds.length !== 3) return '-';

  const margin = ((1 / originalHomeOdds) + (1 / originalDrawOdds) + (1 / originalAwayOdds)) - 1;

  if (margin < -0.09 || margin > 0.09) {
    return '-';
  }

  const n = bookmakerOdds.length;
  const odds = bookmakerOdds[outcomeIndex];
  const fairOdd = (n * odds) / (n - (margin * odds));
  return fairOdd.toFixed(3);
}

export function calculateFair2WayOdds(bookmakerOdds, outcomeIndex, originalHomeOdds, originalAwayOdds) {
  if (bookmakerOdds.length !== 2) return '-';

  const margin = (1 / originalHomeOdds) + (1 / originalAwayOdds) - 1;

  if (margin < -0.08 || margin > 0.08) {
    return '-';
  }

  const n = bookmakerOdds.length;
  const odds = bookmakerOdds[outcomeIndex];
  const fairOdd = (n * odds) / (n - (margin * odds));
  return fairOdd.toFixed(3);
}

export function getSecondsAgo(timestamp) {
  const now = Date.now();
  const diffInSeconds = Math.floor((now - timestamp) / 1000);
  
  if (diffInSeconds >= 10800) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} tuntia sitten`;
  } else if (diffInSeconds >= 600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minuuttia sitten`;
  } else {
    return `${diffInSeconds} sekuntia sitten`;
  }
}

export function getOddsChangeStyle(change, type) {
  const oldValue = parseFloat(change.oldValue);
  const newValue = parseFloat(change.newValue);
  
  if (oldValue < newValue) {
    return type === 'old' ? 'color: #ff6b6b;' : 'color: #51cf66;';
  } else if (oldValue > newValue) {
    return type === 'old' ? 'color: #51cf66;' : 'color: #ff6b6b;';
  }
  return '';
}

export function sendTelegramNotification(message) {
  if (this.telegramEnabled && this.telegramToken && this.telegramChatId) {
    const url = `https://api.telegram.org/bot${this.telegramToken}/sendMessage`;
    const params = new URLSearchParams({
      chat_id: this.telegramChatId,
      text: message,
      parse_mode: 'HTML',
    });

    fetch(`${url}?${params}`)
      .then(response => response.json())
      .then(data => {
        if (!data.ok) {
          console.error('Failed to send Telegram notification:', data.description);
        }
      })
      .catch(error => {
        console.error('Error sending Telegram notification:', error);
      });
  }
}

export function getDirectionColor(direction) {
  switch(direction) {
    case 'up': return 'green';
    case 'down': return 'red';
    case 'added': return 'blue';
    case 'removed': return 'orange';
    default: return 'grey';
  }
}

export function getDirectionIcon(direction) {
  switch(direction) {
    case 'up': return 'mdi-arrow-up';
    case 'down': return 'mdi-arrow-down';
    case 'added': return 'mdi-plus';
    case 'removed': return 'mdi-minus';
    case 'unchanged': return 'mdi-equal';
    default: return 'mdi-help';
  }
}

export function parseDateTime(dateTimeString) {
  if (!dateTimeString || typeof dateTimeString !== 'string') {
    return new Date(0); 
  }
  const parts = dateTimeString.trim().split(' ');
  if (parts.length !== 2) {
    return new Date(0);
  }
  const [datePart, timePart] = parts;
  const [day, month, year] = datePart.split('.');
  const [hour, minute] = timePart.split('.');

  if ([day, month, year, hour, minute].some(part => isNaN(parseInt(part)))) {
    return new Date(0);
  }
  
  return new Date(year, month - 1, day, hour, minute);
}

export function formatHiddenTime(hideUntil) {
  const now = Date.now();
  const remainingTime = hideUntil - now;
  if (remainingTime <= 0) {
    return 'Expired';
  }
  const minutes = Math.floor(remainingTime / 60000);
  const hours = Math.floor(minutes / 60);
  if (hours > 0) {
    return `${hours}h ${minutes % 60}m`;
  }
  return `${minutes}m`;
}

export function initAudio() {
  const sounds = {
    beep1: beep1,
    beep2: beep2,
    beep3: beep3,
    beep4: beep4,
    beep5: beep5,
    beep6: beep6,
    sound1: sound1,
    sound2: sound2
  };
  
  this.audio = new Audio(sounds[this.selectedSound || 'beep']);
  this.audio.addEventListener('error', (e) => {
    console.error('Error loading audio:', e);
  });
  this.audio.load();
}

export function playNotificationSound(playbackRate = 1) {
  if (this.soundEnabled && this.audio) {
    this.audio.playbackRate = playbackRate;
    this.audio.play().catch(error => console.error("Error playing audio:", error));
  }
}

export function getBackgroundColor(value) {
  const minValue = -11;
  const maxValue = 6;
  const normalizedValue = Math.max(minValue, Math.min(maxValue, value)) / maxValue;
  
  if (value < 0) {
    const r = 255;
    const g = Math.round(255 * (normalizedValue + 1));
    return `rgba(${r}, ${g}, 0, 0.3)`;
  } else {
    const r = Math.round(255 * (1 - normalizedValue));
    const g = 255;
    return `rgba(${r}, ${g}, 0, 0.3)`;
  }
}

export function getTextColor(value) {
  const brightness = this.getColorBrightness(this.getBackgroundColor(value));
  return brightness > 128 ? '#000000' : '#FFFFFF';
}

export function getColorBrightness(rgba) {
  const values = rgba.match(/\d+/g).map(Number);
  return (values[0] * 299 + values[1] * 587 + values[2] * 114) / 1000;
}

export function getTopOddsStyle(oddsData) {
  const topOddsInfo = this.getOddsValue(oddsData, 'top');
  if (topOddsInfo.bookie && this.bookieColors[topOddsInfo.bookie]) {
    const bgColor = this.darkMode
      ? this.bookieColors[topOddsInfo.bookie].dark
      : this.bookieColors[topOddsInfo.bookie].light;
    
    return {
      backgroundColor: bgColor,
    };
  }
  return {};
}

export function getContrastYIQ(hexcolor) {
  if (!hexcolor) return 'black';
  let color = hexcolor.replace('#', '');
  if (color.length === 3) {
    color = color.split('').map(c => c + c).join('');
  }
  const r = parseInt(color.substr(0,2),16);
  const g = parseInt(color.substr(2,2),16);
  const b = parseInt(color.substr(4,2),16);
  const yiq = ((r*299)+(g*587)+(b*114))/1000;
  return (yiq >= 128) ? 'black' : 'white';
}

export function focusSearch() {
  this.$refs.searchField.focus();
}

export function togglePlayedStatus(matchId) {
  if (!matchId) return; 

  if (this.playedMatches[matchId]) {
    delete this.playedMatches[matchId];
  } else {
    this.playedMatches[matchId] = true;
  }
  this.playedMatches = { ...this.playedMatches };
  localStorage.setItem('playedMatches', JSON.stringify(this.playedMatches));
}

export function handleKeyPress(event) {
  if (!event || !event.key) return;  // Add safety check
  
  const isInputFocused = ['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName);
  const key = event.key.toLowerCase();
  
  if (key === 'l' && this.selectedMatchId && !isInputFocused) {
    event.preventDefault();
    this.togglePlayedStatus(this.selectedMatchId);
  } else if (key === 's' && !isInputFocused) {
    event.preventDefault();
    this.focusSearch();
  } else if (key === 'delete' && this.selectedMatchId && !isInputFocused) {
    event.preventDefault();
    this.hideSelectedMatch();
  }
}

export function hideMatch(match, duration) {
  if (match && match.common_id) {
    const hideUntil = Date.now() + duration;
    this.hiddenMatches[match.common_id] = {
      hideUntil: hideUntil,
      home: match.home,
      away: match.away,
      start_time: match.start_time
    };
    if (this.pinnedMatchId === match.common_id) {
      this.pinnedMatchId = null;
      this.selectedMatchId = null;
    }
  } else {
    console.error('Attempted to hide a match without valid data', match);
  }
}

export function isMatchHidden(match) {
  if (!match || !match.common_id) {
    console.error('Invalid match object', match);
    return false;
  }
  const hiddenData = this.hiddenMatches[match.common_id];
  if (hiddenData && Date.now() < hiddenData.hideUntil) {
    return true;
  }
  if (hiddenData) {
    delete this.hiddenMatches[match.common_id];
  }
  return false;
}

export function unhideMatch(matchId) {
  delete this.hiddenMatches[matchId];
  this.$forceUpdate();
}

export function hideSelectedMatch() {
  if (this.selectedMatchId) {
    const match = this.nestedMatches.find(m => m.common_id === this.selectedMatchId);
    if (match) {
      const removeOption = this.hideDurations[this.hideDurations.length - 1];
      this.hideMatch(match, removeOption.value);
      this.selectedMatchId = null;
    }
  }
}

export function updateBookieLatency(bookie, timestamp) {
  const currentTime = Date.now();
  let latency = currentTime - timestamp;

  if (bookie.toLowerCase() === 'veikkaus') {
    latency += 10000;
  }

  if (bookie.toLowerCase() === 'betfair') {
    latency += 500;
  }

  if (bookie.toLowerCase() === 'nubet') {
    latency += 10000;
  }

  if (bookie.toLowerCase() === 'pinnacle') {
    latency += 1000;
  }

  if (!this.bookieLatencies[bookie]) {
    this.bookieLatencies[bookie] = [];
  }

  this.bookieLatencies[bookie].push(latency);
  if (this.bookieLatencies[bookie].length > 10) {
    this.bookieLatencies[bookie].shift();
  }

  this.bookieLastUpdateTimes[bookie] = currentTime;

  if (this.bookieLatencies[bookie].length > 1) {
    const sum = this.bookieLatencies[bookie].reduce((a, b) => a + b, 0);
    this.bookieAverageLatencies[bookie] = Math.round(sum / this.bookieLatencies[bookie].length);
  }

  // Check if bookie was previously stale and is now receiving updates
  if (!this.enabledBookies[bookie] && this.bookieLastUpdateTimes[bookie]) {
    const secondsSinceLastUpdate = (currentTime - this.bookieLastUpdateTimes[bookie]) / 1000;
    const threshold = {
      'veikkaus': 600,    // 10 minutes
      'nubet': 300,       // 5 minutes
      'pinnacle': 180,    // 3 minutes
      'betfair': 60       // 1 minute
    }[bookie] || 300;

    if (secondsSinceLastUpdate <= threshold) {
      this.enabledBookies[bookie] = true;
      this.$nextTick(() => {
        this.updatePaginatedMatches();
        window.location.reload();
      });
    }
  }
}

export function getLatencyColor(latency) {
  if (latency < 5000) return 'green';
  if (latency < 7500) return 'yellow';
  if (latency < 10000) return 'orange';
  return 'red';
}

export function formatLatency(latency) {
  return latency ? `${latency}ms` : 'N/A';
}

export function formatLastUpdateTime(timestamp) {
  if (!timestamp) return 'N/A';
  const date = new Date(timestamp);
  return date.toLocaleTimeString();
}

export function updateBookieMatchCounts() {
  const counts = {};
  const bookieMatches = {};  // Track unique matches per bookie

  this.bookieOrder.forEach(bookie => {
    if (bookie !== 'top') {
      counts[bookie] = 0;
      bookieMatches[bookie] = new Set();  // Use Set to track unique common_ids
    }
  });

  this.nestedMatches.forEach(match => {
    if (!match.common_id) return;  // Skip if no common_id

    for (const marketName in match.odds) {
      for (const outcomeName in match.odds[marketName]) {
        this.bookieOrder.forEach(bookie => {
          if (bookie !== 'top' && match.odds[marketName][outcomeName][bookie]) {
            bookieMatches[bookie].add(match.common_id);  // Add common_id to Set
          }
        });
      }
    }
  });

  // Convert Sets to counts
  this.bookieOrder.forEach(bookie => {
    if (bookie !== 'top') {
      counts[bookie] = bookieMatches[bookie].size;
    }
  });

  this.bookieMatchCounts = counts;
}

export function formatValue(oddsRatio) {
  if (oddsRatio && !isNaN(oddsRatio)) {
    return ((oddsRatio - 1) * 100).toFixed(2) + '%';
  }
  return 'N/A';
}

export function calculateKellyStake(fairOdds, bookieOdds) {
  const probability = 1 / fairOdds;
  const decimalOdds = bookieOdds - 1;
  const kellyFraction = ((probability * decimalOdds - (1 - probability)) / decimalOdds) * 0.3;
  
  const maxStake = this.bankroll * 0.025;
  const recommendedStake = Math.min(this.bankroll * kellyFraction, maxStake);
  
  return recommendedStake > 0 ? recommendedStake.toFixed(0) : 0;
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(() => {
  }).catch(err => {
    console.error('Failed to copy text: ', err);
  });
}

export function getMatchTeams(matchId) {
  const match = this.nestedMatches.find(m => m.common_id === matchId);
  if (match) {
    return {
      home: match.home,
      away: match.away
    };
  }
  return {
    home: 'Home Team',
    away: 'Away Team'
  };
}

export function showToastMessage(message, type = 'error', timeout = 5000) {
  this.toastMessage = message;
  this.toastType = type;
  this.toastTimeout = timeout;
  this.showToast = true;
}

export function processOdds(kertoimet, bookie) {
  const odds = {};
  kertoimet.forEach(kerroin => {
      const { Markkina, Tulos, Kerroin, Tasoitus, Panosraja, ID: betfairId, runner_id: runnerId } = kerroin;
      if (!odds[Markkina]) {
          odds[Markkina] = {};
      }

      const validBetfairId = betfairId && !isNaN(betfairId) ? betfairId : null;
      const validRunnerId = runnerId && !isNaN(runnerId) ? runnerId : null;
      const validBetfairHandicap = Tasoitus && !isNaN(Tasoitus) ? Tasoitus : null;
      const floatedPanosraja = Panosraja ? parseFloat(Panosraja) : null;

      if (Markkina === 'Aasialainen tasoitus' || Markkina === 'Yli/Alle') {
          if (!odds[Markkina][Tulos]) {
              odds[Markkina][Tulos] = {};
          }
          if (!odds[Markkina][Tulos][Tasoitus]) {
              odds[Markkina][Tulos][Tasoitus] = {};
          }

          odds[Markkina][Tulos][Tasoitus][bookie] = Kerroin;
          // Add panosraja for any bookie that has it
          if (floatedPanosraja) {
            const adjustedPanosraja = bookie === 'betfair' ? Math.round(floatedPanosraja * 1.2) : floatedPanosraja;
            odds[Markkina][Tulos][Tasoitus][`${bookie}_panosraja`] = adjustedPanosraja;
          }
          
          // Add betfair specific data
          if (bookie === 'betfair' && validBetfairId && validRunnerId) {
              odds[Markkina][Tulos][Tasoitus].betfair_market_id = validBetfairId;
              odds[Markkina][Tulos][Tasoitus].betfair_runner_id = validRunnerId;
              odds[Markkina][Tulos][Tasoitus].betfair_handicap = validBetfairHandicap;
          }
      } else {
          if (!odds[Markkina][Tulos]) {
              odds[Markkina][Tulos] = {};
          }

          odds[Markkina][Tulos][bookie] = Kerroin;
          // Add panosraja for any bookie that has it
          if (floatedPanosraja) {
            const adjustedPanosraja = bookie === 'betfair' ? Math.round(floatedPanosraja * 1.2) : floatedPanosraja;
            odds[Markkina][Tulos][`${bookie}_panosraja`] = adjustedPanosraja;
          }
          
          // Add betfair specific data
          if (bookie === 'betfair' && validBetfairId && validRunnerId) {
              odds[Markkina][Tulos].betfair_market_id = validBetfairId;
              odds[Markkina][Tulos].betfair_runner_id = validRunnerId;
              odds[Markkina][Tulos].betfair_handicap = validBetfairHandicap;
          }
      }
  });
  return odds;
}

export function getOddsValue(oddsData, bookie) {
  const currentTime = Date.now();

  const thresholds = {
    'veikkaus': 600,    // 10 minutes
    'nubet': 300,       // 5 minutes
    'pinnacle': 180,    // 3 minutes
    'betfair': 60       // 1 minute
  };

  const isStale = (b) => {
    if (b === 'prediction' || !this.isConnected) {
      return false;
    }

    const lastUpdateTime = this.bookieLastUpdateTimes[b];
    if (lastUpdateTime === undefined) return false;
    
    const secondsSinceLastUpdate = (currentTime - lastUpdateTime) / 1000;
    const threshold = thresholds[b] || 300;
    const isStale = secondsSinceLastUpdate > threshold;
    
    if (isStale && this.enabledBookies[b]) {
      this.enabledBookies[b] = false;
      this.$nextTick(() => {
        this.showToastMessage(`${b} poistettu käytöstä`, 'warning', 8000);
        window.location.reload();
      });
    }
    else if (!isStale && !this.enabledBookies[b]) {
      this.enabledBookies[b] = true;
      this.$nextTick(() => {
        this.showToastMessage(`${b} päällä`, 'success', 8000);
        window.location.reload();
      });
    }
    
    return isStale;
  };

  if (bookie === 'top') {
    const bookies = this.bookieOrder.filter(b => b !== 'prediction' && b !== 'top');
    let maxOdds = -Infinity;
    let topBookie = null;
    let panosraja = null;

    bookies.forEach(b => {
      if (!isStale(b)) {
        const odds = parseFloat(oddsData[b]);
        if (!isNaN(odds) && odds > maxOdds) {
          maxOdds = odds;
          topBookie = b;
          panosraja = oddsData[`${b}_panosraja`];
        }
      }
    });

    return {
      value: maxOdds > 0 ? maxOdds.toFixed(2) : null,
      bookie: topBookie,
      panosraja: panosraja
    };
  } else {
    if (isStale(bookie)) {
      return {
        value: null,
        bookie: bookie,
        panosraja: null
      };
    }

    const value = oddsData[bookie];
    const panosraja = oddsData[`${bookie}_panosraja`];
    
    const twoDecimalBookies = ['prediction', 'veikkaus', 'nubet', 'pinnacle', 'betfair'];
    const decimals = twoDecimalBookies.includes(bookie.toLowerCase()) ? 2 : 3;

    return {
      value: value && !isNaN(parseFloat(value)) && parseFloat(value) > 0 ? parseFloat(value).toFixed(decimals) : null,
      bookie: bookie,
      panosraja: panosraja
    };
  }
}

export function validateAdjustment(field) {
  if (this.predictionData[field]) {
    if (this.predictionData[field] > 0.2) {
      this.predictionData[field] = 0.2;
    } else if (this.predictionData[field] < -0.2) {
      this.predictionData[field] = -0.2;
    }
    this.predictionData[field] = parseFloat(this.predictionData[field].toFixed(3));
  }
}

export function formatValueDisplay(value) {
  if (!value || value === '-') return value;
  const numValue = parseFloat(value.toString().replace('%', ''));
  return isNaN(numValue) ? value : numValue.toFixed(1) + '%';
}

export function formatPayoutDisplay(payout) {
  if (!payout || payout === '-') return payout;
  const numValue = parseFloat(payout);
  return isNaN(numValue) ? payout : numValue.toFixed(1) + '%';
}

export function handleSoundChange() {
  localStorage.setItem('selectedSound', this.selectedSound);
  this.initAudio();
  this.playNotificationSound();
}

export function formatBetfairDate(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleString('fi-FI', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }).replace(',', '');
}

export function calculateValue(actualOdds, fairOdds, formatAsString = false) {
  if (!actualOdds || !fairOdds || isNaN(actualOdds) || isNaN(fairOdds) || fairOdds === 0) {
    return formatAsString ? 'N/A' : null;
  }
  
  const value = ((actualOdds / fairOdds) - 1) * 100;
  return formatAsString ? value.toFixed(1) + '%' : value;
}
